import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";

// open, handleSubmit, handleClose, longName, setLongName, shortName, setShortName
export function EditObjectDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <form>
                <DialogTitle>Добави обект</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Въведете данни
                    </DialogContentText>
                    <Grid container direction="row" spacing={2}>

                        <Grid item xs={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="longName"
                                value={props.longName}
                                onChange={(event) =>
                                    props.setLongName(event.target.value)
                                }
                                InputLabelProps={{ shrink: props.longName !== null }}
                                label="Пълно име"
                                type="text"
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                id="shortName"
                                value={props.shortName}
                                onChange={(event) =>
                                    props.setShortName(event.target.value)
                                }
                                label="Съкратено име"
                                type="text"
                                variant="standard"
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Отказ</Button>

                    <Button onClick={() => {
                        props.editObject(props.navigate, props.accessToken, props.setAccessToken,
                            props.objectId, props.longName, props.shortName)
                        props.handleClose();
                    }} disabled={!props.longName}>Редактирай</Button>
                </DialogActions>
            </form>
        </Dialog>
    )

}