import {CommonFetch} from "../services/api-fetch";
import moment from "moment";

export const getWorkingGroups = (department, navigate, accessToken, setAccessToken, setWorkingGroups) => {
    console.log('getWorkingGroups dep ', department)
    if (!department || !department.id){
        return
    }
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups?`+ new URLSearchParams({
        id_project: department.id,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setWorkingGroups(result.items)
                console.log(result)
                return result.items
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getWorkingTypes = (department, navigate, accessToken, setAccessToken, stage, setWorkingTypes) => {
    console.log('getWorkingTypes dep ', department)
    if (!department || !department.id){
        return
    }
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks?`+ new URLSearchParams({
        id_aworkgroup: stage.id,
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setWorkingTypes(result.items)
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const addGroup = (department, navigate, accessToken, setAccessToken, setStage, setGroup, name, idAworkgroup="0") => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworkgroups/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup":idAworkgroup,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code":"1",
            "name_group":name,
            "id_project":department.id,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                const value = {
                    "id": result && result.id ? result.id : 0,
                    "name_group": name,
                    "status":"0"
                };

                if (idAworkgroup === "0") {
                    setStage(value);
                } else {
                    setGroup(value)
                }
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}

export const createPartner = (navigate, accessToken, setAccessToken, setAssignee, name) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/add?`+ new URLSearchParams({
        token: accessToken,
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "name_firm": name,
            "phone":"",
            "contact_person":name,
            "id_category":2,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                setAssignee({
                    "id": result && result.id ? result.id : 0,
                    "name_firm": name,
                })
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}


export const addObject = (navigate, accessToken, setAccessToken, objects, setDepartment, name, shortName) => {
    console.log('sending ', `${process.env.REACT_APP_URL}/projects/add?`+ new URLSearchParams({
        token: accessToken
    }));

    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id":"0",
            "code":"1",
            "project_name": name,
            "shortprjname": shortName,
            "status":"0",
            "token": accessToken
        })
    })
        .then(
            (result) => {
                setDepartment({
                    "id": objects ? objects[objects.length - 1].id  + 1 : 0,
                    "name_project": name,
                    "shortprjname": shortName,
                    "status":"0"
                })
                console.log({
                    "id": objects ? objects[objects.length - 1].id  + 1 : 0,
                    "name_project": name,
                    "shortprjname": shortName,
                    "status":"0"
                })

            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}

export const getObjects = (navigate, accessToken, setAccessToken, setObjects) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setObjects(result.items)
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const getUsers = (navigate, accessToken, setAccessToken, setUsers) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setUsers(result.items)
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}
export const getActualUsers = (navigate, accessToken, setAccessToken, setUsers) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/users?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                setUsers(result.items)
                console.log(result)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const addWorkType = (navigate, accessToken, setAccessToken, setWorkType, stage, name) => {
    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/aworks/add?`+ new URLSearchParams({
        token: accessToken
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id_aworkgroup": stage.id,  //0 Главна група, иначе сочи към друга група за дървовидна структура
            "code": "1",
            "name_work": name,
            "edm": "кв.м",
            "qty": "50",
            "price": 0,
            "token": accessToken
        })
    })
        .then(
            (result) => {
                setWorkType({
                    "id": result && result.id ? result.id : 0,
                    "name_work": name,
                    "status":"0"
                })
            },
            (error) => {
                console.log('error: ', error)
            }
        ).catch((e) => {
        console.log(e)
    });
}


export const completeTask = (navigate, accessToken, setAccessToken, popoverTaskId,
                             getTasksFetch, setCompleteOpen, setAnchorEl, date) => {
    console.log(popoverTaskId)

    return CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/setTaskDone`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token":accessToken,
            "id": popoverTaskId,
            "date": date ? date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            "comment":"Приключена е"

        })
    })
        .then(
            (result) => {
                getTasksFetch()
                setCompleteOpen(false)
                setAnchorEl(null)
            },
            (error) => {
                console.log('error: ', error)
                setCompleteOpen(false)
            }
        );


}

export const waitTask = (navigate, accessToken, setAccessToken, popoverTaskId,
                             getTasksFetch, setWaitOpen, setAnchorEl) => {
    console.log(popoverTaskId)

    CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/setTaskWait`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token":accessToken,
            "id": popoverTaskId,
        })
    })
        .then(
            (result) => {
                getTasksFetch()
                setWaitOpen(false)
                setAnchorEl(null)
            },
            (error) => {
                console.log('error: ', error)
                setWaitOpen(false)
            }
        );


}

export const activeTask = (navigate, accessToken, setAccessToken, popoverTaskId,
                             getTasksFetch, setActiveOpen, setAnchorEl) => {
    console.log(popoverTaskId)

    CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/activateTask`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "token":accessToken,
            "id": popoverTaskId,
            "date_start":moment().format("YYYY-MM-DD"),
            "hour_start":""
        })
    })
        .then(
            (result) => {
                getTasksFetch()
                setActiveOpen(false)
                setAnchorEl(null)
            },
            (error) => {
                console.log('error: ', error)
                setActiveOpen(false)
            }
        );


}

export const addTask = (navigate, accessToken, setAccessToken,
                        userId, department, group, workType, assignee, about,
                        data, hour, handler = null) => {
    console.log('about ', about)
    console.log('data ', data)
    console.log('hour ', hour)
    console.log('department ', department)
    console.log('group ', group)
    console.log('workType ', workType)
    console.log('assignee ', assignee)


    CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/add`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token: accessToken,
            id: "0",
            id_user: userId,
            id_project: department && department.id ? department.id : "0",
            id_aworkgroup: group && group.id ? group.id : "0",
            id_work: workType && workType.id ? workType.id : "0",
            id_partner: assignee ? assignee.id : "0",
            subject: about,
            status: "0",
            date_start: data ? data.format("YYYY-MM-DD") : null,
            hour_start: hour,
            id_creater: userId,
        })
    })
        .then(
            (result) => {
                console.log(result)
                if (handler) {
                    handler(result)
                }
            },
            (error) => {
                console.log('error: ', error)
            }
        );
    if (!handler) {
        navigate('/home')
    }

}

export const deleteTask = (navigate, accessToken, setAccessToken, popoverTaskId, getTasksFetch, setDeleteDialogOpen, setAnchorEl) => {

    CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/delete?`+ new URLSearchParams({
        token: accessToken,
        id: popoverTaskId
    }), {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                getTasksFetch()
                setDeleteDialogOpen(false)
                setAnchorEl(null)
            },
            (error) => {
                console.log('error: ', error)
                setDeleteDialogOpen(false)
            }
        );


}

function jsonConcat(o1, o2) {
    for (var key in o2) {
        o1[key] = o2[key];
    }
    return o1;
}

export const addDetail = (navigate, accessToken, setAccessToken,
                        userId, taskId, date, comment, quantity, hour, roadCard = null, attachment = null) => {

    let body = {
        token: accessToken,
        id_creater: userId,
        id_tasks:taskId,
        date_task: date ? date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        comment: comment,
        qty: quantity,
        type_task: roadCard ? "3" : (quantity ? "2" : "1"),
        hour_start: hour
    };

    if (roadCard) {
        body = jsonConcat(body, roadCard);
    }

    let formdata = new FormData();

    for (let key in body) {
        if (body.hasOwnProperty(key)) {
            if (body[key]) {
                formdata.append(key, body[key]);
            }
        }
    }


    if (attachment) {
        for (const attachemntItem of attachment) {
            formdata.append("taskfiles[]", attachemntItem, attachemntItem.name);
        }
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/addDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
        },
        body: formdata
    })

}

export const editDetail = (navigate, accessToken, setAccessToken,
                        userId, id, taskId, date, hour, comment, quantity, roadCard = null, attachment = null) => {

    let body = {
        id: id,
        token: accessToken,
        id_creater: userId,
        id_tasks:taskId,
        date_task: date ? date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        hour_start: hour,
        comment: comment,
        qty: quantity,
        type_task: roadCard ? "3" : (quantity ? "2" : "1"),
    };

    if (roadCard) {
        body = jsonConcat(body, roadCard);
    }

    let formdata = new FormData();

    for (let key in body) {
        if (body.hasOwnProperty(key)) {
            if (body[key]) {
                formdata.append(key, body[key]);
            }
        }
    }


    if (attachment) {
        for (const attachemntItem of attachment) {
            formdata.append("taskfiles[]", attachemntItem, attachemntItem.name);
        }
    }


    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/updateDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
        },
        body: formdata
    })

}


export const getTasksRangeFetch = (navigate, accessToken, setAccessToken, userId, setTasks,
                                   fromdate, todate) => {
    CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks?`+ new URLSearchParams({
        token: accessToken,
        userID: userId,
        fromdate: fromdate,
        todate: todate
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(
            (result) => {
                console.log(result)
                let tasks = result.items;
                /*  tasks.sort((a,b) => {
                      if (a.project_name && b.project_name) {
                          return a.project_name.localeCompare(b.project_name)
                      }
                      return 0;
                  })
*/
                setTasks(tasks)
            },
            (error) => {
                console.log('error: ', error)
            }
        );
}

export const editTask = (navigate, accessToken, setAccessToken,
                         task, date) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/update`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": task.id,
            "id_user": task.id_user,
            "id_project": task.id_project,
            "id_etap": task.id_etap,
            "id_aworkgroup": task.id_aworkgroup,
            "id_work": task.id_work,
            "id_partner": task.id_partner,
            "subject": task.subject,
            "status": task.status,
            "date_start": date,
            "hour_start": task.hour_start ? moment(task.hour_start).format("HH:mm") : null,
            "token": accessToken
        })
    });
}

export const removeVisibleUsers = (navigate, accessToken, setAccessToken,
                         taskId, userId) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/deleteTaskUser`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "task_id": taskId,
            "id": taskId,
            "user_id": userId,
            "token": accessToken
        })
    });
}


export const loadTask = (navigate, accessToken, setAccessToken, taskId) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/get?`+ new URLSearchParams({
        token: accessToken,
        id: taskId
    }), {
        method: "GET",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export const deleteDetail = (navigate, accessToken, setAccessToken, commentId) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/deleteDetail`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": commentId,
            "token": accessToken
        })
    })
}

export const deleteAttachment = (navigate, accessToken, setAccessToken, attachmentId) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/tasks/deleteAttachment`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": attachmentId,
            "token": accessToken
        })
    })
}

export const deleteObject = (navigate, accessToken, setAccessToken, objectId) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/delete`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": objectId,
            "token": accessToken
        })
    })
}
export const deleteAssignee = (navigate, accessToken, setAccessToken, objectId) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/partners/delete`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": objectId,
            "token": accessToken
        })
    })
}

export const editObject = (navigate, accessToken, setAccessToken,
                           objectId, longNme, shortName) => {
    return CommonFetch(navigate, setAccessToken,`${process.env.REACT_APP_URL}/projects/update`, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "id": objectId,
            "code":"1",
            "project_name": longNme,
            "shortprjname": shortName,
            "status":"0",
            "token": accessToken
        })
    })
}