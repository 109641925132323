import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import LogoutIcon from '@mui/icons-material/Logout';
import {useLocalStorage} from "./useLocalStorage";
import {useNavigate} from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Switch from "@mui/material/Switch";
import { matchRoutes, useLocation } from "react-router-dom"
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

function SideMenu(props) {
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [locationView, setLocationView] = useLocalStorage("locationView", false);
    let navigate = useNavigate();
    const location = useLocation()

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={() => {
                props.setOpen(false)
            }}
            onKeyDown={() => {
                props.setOpen(false)
            }}
        >
            <List>

               {/* <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        navigate('/waiting/tasks')
                    }}>
                        <ListItemIcon>
                            <HourglassBottomIcon />
                        </ListItemIcon>
                        <ListItemText id="switch-list-waiting" primary="Чакащи задачи" />
                    </ListItemButton>
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" primary="Изглед по обекти" />
                    <Switch
                        edge="end"
                        onChange={() => {
                            if(props.setView) {
                                props.setView(!locationView)
                                if (location.pathname !== 'home') {
                                    navigate('/home');
                                }
                            }
                            setLocationView(!locationView)
                        }}
                        checked={locationView}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi',
                        }}
                    />
                </ListItem>*/}

                <ListItem key={'Изход'} disablePadding>
                    <ListItemButton onClick={() => {
                        setAccessToken(null)
                        navigate('/login')
                    }}>
                        <ListItemIcon >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Изход'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <React.Fragment key={'left'}>
            <Drawer
                anchor={'left'}
                open={props.isOpen}
                onClose={() => {
                    props.setOpen(false)
                }}
            >
                {list('left')}
            </Drawer>
        </React.Fragment>
    );
}

export default SideMenu;
