import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import RadioGroup from "@mui/material/RadioGroup";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import moment from "moment";
import {useParams} from "react-router";

// title, description, setCancelDialogOpen, handleOk, positiveText, negativeText
function ConfirmationDialog(props) {
    let params = useParams();

    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        props.setCancelDialogOpen(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Typography>{props.description}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="error">
                    {props.negativeText}
                </Button>
                <Button onClick={props.handleOk}>{props.positiveText}</Button>
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmationDialog;