import './App.css';
import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import {useLocalStorage} from "./useLocalStorage";
import {useLocation, useNavigate} from "react-router-dom";
import {deleteObject, editObject, getObjects} from "./utils/NetworkUtils";
import Typography from "@mui/material/Typography";
import ConfirmationDialog from "./dialogs/ConfirmationDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import {EditObjectDialog} from "./dialogs/EditObjectDialog";
import Grid from "@mui/material/Grid";
import SummarizeIcon from '@mui/icons-material/Summarize';
import {Search, SearchIconWrapper, StyledInputBase} from "./components/SearchComponent";
import SearchIcon from '@mui/icons-material/Search';

function RightObjectSideMenu(props) {
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [locationView, setLocationView] = useLocalStorage("locationView", false);
    let navigate = useNavigate();
    const location = useLocation()

    const [searchTerm, setSearchTerm] = React.useState(null);
    const [objects, setObjects] = React.useState(null);

    // UPDATE
    const [objectId, setObjectId] = React.useState(null);
    const [departmentLong, setDepartmentLong] = React.useState(null);
    const [departmentShort, setDepartmentShort] = React.useState(null);

    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    useEffect(() => {
        getObjects(navigate, accessToken, setAccessToken, setObjects);
    }, []);

    const list = (anchor) => (
        <Box
            sx={{ right: 20 }}
            role="presentation"
        >
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Търсене…"
                    onChange={(e) => {
                        setSearchTerm(e.target.value)
                    }}
                    inputProps={{ 'aria-label': 'търсене' }}
                />
            </Search>
            <List>
                {objects && objects
                    .filter((object) => {
                        if (!searchTerm || searchTerm == "") {
                            return true
                        }
                        return object.name_project && object.name_project.toLowerCase().includes(searchTerm.toLowerCase())
                    })
                    .map(object => {
                    return <Grid container>
                        <ListItemButton onClick={() => {
                            navigate('/home/object/'+object.id+"?name="+object.name_project)
                        }}>
                            <Typography>{object.name_project}</Typography>
                        </ListItemButton>

                {/*        <IconButton onClick={() => {
                            navigate('/home/object/'+object.id+"/report?name="+object.name_project)
                        }}>
                            <SummarizeIcon/>
                        </IconButton>*/}

                            <IconButton onClick={() => {
                                setObjectId(object.id)
                                setDepartmentLong(object.name_project)
                                setDepartmentShort(object.shortprjname)
                                setEditDialogOpen(true)
                            }}>
                                <EditIcon/>
                            </IconButton>

                            <IconButton onClick={() => {
                                setObjectId(object.id)
                                setDeleteDialogOpen(true)
                            }}>
                                <DeleteIcon/>
                            </IconButton>
                    </Grid>

                })}


            </List>
        </Box>
    );

    return (
        <React.Fragment key={'right'}>

            <ConfirmationDialog
                title={'Изтриване'}
                description={'Наистина ли желаете ли да изтриете този обект?'}
                open={deleteDialogOpen}
                setCancelDialogOpen={setDeleteDialogOpen}
                handleOk={() => {
                    setDeleteDialogOpen(false)
                    deleteObject(navigate, accessToken, setAccessToken, objectId)
                        .then(r => {
                            getObjects(navigate, accessToken, setAccessToken, setObjects)
                        })
                }}
                positiveText={'Изтрий'}
                negativeText={'Отказ'}
            />


            <EditObjectDialog
                open={editDialogOpen}
                handleSubmit={() => {
                    //props.addObject(props.department.name_project, props.departmentShort)
                }}
                handleClose={() => {
                    setEditDialogOpen(false)
                    getObjects(navigate, accessToken, setAccessToken, setObjects)
                }}

                navigate={navigate}
                accessToken={accessToken}
                setAccessToken={setAccessToken}

                objectId={objectId}
                longName={departmentLong}
                setLongName={setDepartmentLong}
                shortName={departmentShort}
                setShortName={setDepartmentShort}

                editObject={editObject}
            />

            <Drawer
                anchor={'right'}
                open={props.isOpen}
                onClose={() => {
                    props.setOpen(false)
                }}
            >
                {list('right')}
            </Drawer>
        </React.Fragment>
    );
}

export default RightObjectSideMenu;
