import '../App.css';
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SideMenu from "../SideMenu";
import {useLocalStorage} from "../useLocalStorage";
import RightObjectSideMenu from "../RightObjectSideMenu";
import DomainIcon from '@mui/icons-material/Domain';
import Tooltip from "@mui/material/Tooltip";
import RightAssigneesSideMenu from "../RightAssigneesSideMenu";
import PeopleIcon from '@mui/icons-material/People';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {useNavigate} from "react-router-dom";

function AppBarComponent(props) {
    const [auth, setAuth] = React.useState(true);
    const [isOpen, setOpen] = React.useState(false);
    const [isObjectsOpen, setObjectsOpen] = React.useState(false);
    const [isAssigneesOpen, setAssigneesOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <SideMenu
                isOpen={isOpen}
                setOpen={setOpen}
                setView={props.setView}/>

            <RightObjectSideMenu
                isOpen={isObjectsOpen}
                setOpen={setObjectsOpen}
                setView={props.setView}/>

            <RightAssigneesSideMenu
                isOpen={isAssigneesOpen}
                setOpen={setAssigneesOpen}
                setView={props.setView}/>

            <Toolbar>
                { accessToken &&
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setOpen(true)
                        }}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                }
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Управление на задачи
                </Typography>
                {auth && (
                    <div>
                        {accessToken &&

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                        }
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>My account</MenuItem>
                        </Menu>
                    </div>
                )}

                { accessToken &&
                    <Tooltip title={"Чакащи задачи"}>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 1}}
                            onClick={() => {
                                navigate('/waiting/tasks')
                            }}
                        >
                            <HourglassBottomIcon/>
                        </IconButton>
                    </Tooltip>
                }

                { accessToken &&
                    <Tooltip title={"Изпълнители"}>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => {
                                setAssigneesOpen(true)
                            }}
                            sx={{mr: 1}}
                        >
                            <PeopleIcon/>
                        </IconButton>
                    </Tooltip>
                }

                { accessToken &&
                    <Tooltip title={"Обекти"}>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => {
                                setObjectsOpen(true)
                            }}
                            sx={{mr: 1}}
                        >
                            <DomainIcon/>
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;
