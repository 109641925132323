import './App.css';
import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import {useLocalStorage} from "./useLocalStorage";
import {useLocation, useNavigate} from "react-router-dom";
import {deleteAssignee, deleteObject, getObjects, getUsers} from "./utils/NetworkUtils";
import Typography from "@mui/material/Typography";
import {Search, SearchIconWrapper, StyledInputBase} from "./components/SearchComponent";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import ConfirmationDialog from "./dialogs/ConfirmationDialog";

function RightAssigneesSideMenu(props) {
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [locationView, setLocationView] = useLocalStorage("locationView", false);
    let navigate = useNavigate();
    const location = useLocation()

    const [searchTerm, setSearchTerm] = React.useState(null);
    const [assignees, setAssignees] = React.useState(null);

    const [objectId, setObjectId] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    useEffect(() => {
        getUsers(navigate, accessToken, setAccessToken, setAssignees);
    }, []);

    const list = (anchor) => (
        <Box
            sx={{ right: 20 }}
            role="presentation"
        >
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Търсене…"
                    onChange={(e) => {
                        setSearchTerm(e.target.value)
                    }}
                    inputProps={{ 'aria-label': 'търсене' }}
                />
            </Search>

            <List>
                {assignees && assignees
                    .filter((assignee) => {
                        if (!searchTerm || searchTerm == "") {
                            return true
                        }
                        return assignee.name_firm && assignee.name_firm.toLowerCase().includes(searchTerm.toLowerCase())
                    }).map(object => {
                    return<Grid container justifyContent={'space-between'} style={{margin: 5, paddingRight: 10}}>
                            <ListItemButton onClick={() => {
                                navigate('/home/partner/'+object.id)
                            }}>
                                <Typography>{object.name_firm}</Typography>
                            </ListItemButton>

                            <IconButton onClick={() => {
                                setObjectId(object.id)
                                setDeleteDialogOpen(true)
                            }}>
                                <DeleteIcon/>
                            </IconButton>
                        </Grid>
                    })}


            </List>

            <ConfirmationDialog
                title={'Изтриване'}
                description={'Наистина ли желаете ли да изтриете този изпълнител?'}
                open={deleteDialogOpen}
                setCancelDialogOpen={setDeleteDialogOpen}
                handleOk={() => {
                    setDeleteDialogOpen(false)
                    deleteAssignee(navigate, accessToken, setAccessToken, objectId)
                        .then(r => {
                            getUsers(navigate, accessToken, setAccessToken, setAssignees);
                        })
                }}
                positiveText={'Изтрий'}
                negativeText={'Отказ'}
            />
        </Box>
    );

    return (
        <React.Fragment key={'right'}>
            <Drawer
                anchor={'right'}
                open={props.isOpen}
                onClose={() => {
                    props.setOpen(false)
                }}
            >
                {list('right')}
            </Drawer>
        </React.Fragment>
    );
}

export default RightAssigneesSideMenu;
