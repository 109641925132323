import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import RadioGroup from "@mui/material/RadioGroup";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import moment from "moment";
import {useParams} from "react-router";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Slide from "@mui/material/Slide";
import {deleteAttachment} from "../utils/NetworkUtils";
import IconButton from "@mui/material/IconButton";

// title, description, setCancelDialogOpen, handleOk, positiveText, negativeText
function HoverDeleteDetail(props) {

    return (

        props.attachmentId === props.commentHover &&
        <Slide direction="down" in={props.attachmentId === props.commentHover}>
            <Grid container
                  justifyContent={'center'}>
                <Tooltip
                    title="Изтрий прикачен файл">
                    <IconButton>

                        <DeleteIcon onClick={() => {
                            deleteAttachment(props.navigate, props.accessToken,
                                props.setAccessToken, props.attachmentId)
                                .then(r => {
                                    props.setAnchorEl(null)
                                })
                        }}/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Slide>

    );
}
export default HoverDeleteDetail;