import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import ListScreen from "./screens/ListScreen";
import AddTaskScreen from "./screens/AddTaskScreen";
import LoginScreen from "./screens/LoginScreen";
import EditTaskScreen from "./screens/EditTaskScreen";
import ListTasksForObjectScreen from "./screens/ListTasksForObjectScreen";
import ListTasksForPartnerScreen from "./screens/ListTasksForPartnerScreen";
import ObjectReportScreen from "./screens/ObjectReportScreen";
import WaitingTasksScreen from "./screens/WaitingTasksScreen";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route exact path="/" element={<Navigate to="/login"/>} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/home" element={<ListScreen />} />

            </Routes>
        </div>
    );
}

export default App;
