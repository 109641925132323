import '../App.css';
import React, {useEffect, useState} from "react";
import AppBarComponent from "./AppBarComponent";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {useLocalStorage} from "../useLocalStorage";
import {CommonFetch} from "../services/api-fetch";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import ListForObjectsComponent from "../components/ListForObjectsComponent";
import ListForDayComponent from "../components/ListForDayComponent";
import AddNewObjectDialog from "../dialogs/AddDetailDialog";
import {activeTask, addDetail, completeTask, waitTask} from "../utils/NetworkUtils";
import DuplicateDialog from "../dialogs/DuplicateDialog";

function ListScreen() {
    let navigate = useNavigate();
    const [locationView, setLocationView] = useLocalStorage("locationView", false);
    const [locationViewState, setLocationViewState] = useState(false);

    const [calendarVisible, setCalendarVisible] = useState(true);

    const [date, setDate] = useState(moment(new Date()));
    const [ranges, setRanges] = React.useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [valueChose, setValue] = React.useState(null);

    const [tasks, setTasks] = React.useState([]);
    const [newTask, setNewTask] = React.useState(null);

    //POPOVER
    const [popoverTaskId, setPopoverTaskId] = React.useState(null);
    const [popoverObject, setPopoverObject] = React.useState(null);
    const [popoverObjectId, setPopoverObjectId] = React.useState(null);
    const [popoverStage, setPopoverStage] = React.useState(null);
    const [popoverGroup, setPopoverGroup] = React.useState(null);
    const [popoverWorkType, setPopoverWorkType] = React.useState(null);
    const [popoverNameFirm, setPopoverNameFirm] = React.useState(null);
    const [popoverIdFirm, setPopoverIdFirm] = React.useState(null);
    const [popoverDate, setPopoverDate] = React.useState(null);
    const [popoverHour, setPopoverHour] = React.useState(null);
    const [popoverAdditionalProperties, setPopoverAdditionalProperties] = React.useState({});
    const [popoverDetails, setPopoverDetails] = React.useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    //DUPLICATE
    const [duplicateOpen, setDuplicateOpen] = React.useState(false);

    //MOVE
    const [moveOpen, setMoveOpen] = React.useState(false);


    const open = Boolean(anchorEl);

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [userId, setUserId] = useLocalStorage("userId", null);

    // Add detail
    const [addDetailDialogOpen, setAddDetailDialogOpen] = React.useState(false);
    const [comment, setComment] = React.useState(null);
    const [detailDate, setDetailDate] = React.useState(moment(new Date()));
    const [detailHour, setDetailHour] = React.useState(null);
    const [detailQuantity, setDetailQuantity] = React.useState(null);
    // --- Road list
    const [isRoadListOpen, setRoadListOpen] = React.useState(false);
    const [roadAssignee, setRoadAssignee] = React.useState(null);
    const [fuelPrice, setFuelPrice] = React.useState(null);
    const [dashboardStart, setDashboardStart] = React.useState(null);
    const [dashboardEnd, setDashboardEnd] = React.useState(null);

    const [completeOpen, setCompleteOpen] = React.useState(false);
    const [waitOpen, setWaitOpen] = React.useState(false);
    const [activeOpen, setActiveOpen] = React.useState(false);


    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getTasksFetch(date)
        setLocationViewState(locationView)
    }, [])

    const getTasksFetch = (datePassed = date, setTasksFunc = setTasks) => {
        CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks?`+ new URLSearchParams({
            token: accessToken,
            userID: userId,
            date: datePassed.format("YYYY-MM-DD")
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(
                (result) => {
                    console.log(result)
                    let tasks = result.items;
                    tasks.sort((a,b) => {
                        if (a.project_name && b.project_name) {
                            return a.project_name.localeCompare(b.project_name)
                        }
                        return 0;
                    })

                    setTasksFunc(tasks)
                },
                (error) => {
                    console.log('error: ', error)
                }
            );
    }

    const deleteTask = () => {
        console.log(popoverTaskId)

        CommonFetch(navigate, setAccessToken, `${process.env.REACT_APP_URL}/tasks/delete?`+ new URLSearchParams({
            token: accessToken,
            id: popoverTaskId
        }), {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(
                (result) => {
                    getTasksFetch(date)
                    setDeleteDialogOpen(false)
                    setNewTask(result)
                    setAnchorEl(null)
                },
                (error) => {
                    console.log('error: ', error)
                    setDeleteDialogOpen(false)
                }
            );


    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'name',
            headerName: 'Име на задача',
            width: 150,
            editable: true,
        },
        {
            field: 'assignee',
            headerName: 'Изпълнител',
            width: 150,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Статус',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            field: 'expenses',
            headerName: 'Разходи',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
        },
    ];

    const rows = [
        { id: 1, name: 'Задача 1', status: 'В прогрес', expenses: '5' },
        { id: 2, name: 'Задача 2', status: 'В прогрес', expenses: 42 },
        { id: 3, name: 'Задача 3', status: 'В прогрес', expenses: 45 },
        { id: 4, name: 'Задача 4', status: 'В прогрес', expenses: 16 },
        { id: 5, name: 'Задача 5', status: 'В прогрес', expenses: 4 },
        { id: 6, name: 'Задача 6', status: 'В прогрес', expenses: 150 },
        { id: 7, name: 'Задача 7', status: 'В прогрес', expenses: 44 },
        { id: 8, name: 'Задача 8', status: 'В прогрес', expenses: 36 },
        { id: 9, name: 'Задача 8', status: 'В прогрес', expenses: 65 },
    ]

    return (
        <>
        </>
    )

    /*return (
        <React.Fragment>
            <AppBarComponent
                setView={setLocationViewState}/>
            <br/>

            <AddNewObjectDialog
                navigate={navigate}
                accessToken={accessToken}
                setAccessToken={setAccessToken}
                open={addDetailDialogOpen}
                handleClose={() => setAddDetailDialogOpen(false)}
                comment={comment}
                setComment={setComment}
                date={detailDate}
                setDate={setDetailDate}
                hour={detailHour}
                setHour={setDetailHour}
                userId={userId}
                taskId={popoverTaskId}
                details={popoverDetails}
                setPopoverOpen={setAnchorEl}
                setPopoverDetails={setPopoverDetails}

                isRoadListOpen={isRoadListOpen}
                setRoadListOpen={setRoadListOpen}
                detailQuantity={detailQuantity}
                setDetailQuantity={setDetailQuantity}
                roadAssignee={roadAssignee}
                setRoadAssignee={setRoadAssignee}
                fuelPrice={fuelPrice}
                setFuelPrice={setFuelPrice}
                dashboardStart={dashboardStart}
                setDashboardStart={setDashboardStart}
                dashboardEnd={dashboardEnd}
                setDashboardEnd={setDashboardEnd}

                addDetail={addDetail}
                />
            {
                !locationViewState ?
                <ListForDayComponent
                    setCalendarVisible={setCalendarVisible}
                    calendarVisible={calendarVisible}
                    date={date}
                    setDate={setDate}
                    ranges={ranges}
                    setRanges={setRanges}
                    getTasksFetch={getTasksFetch}
                    tasks={tasks}
                    open={open}
                    setPopoverTaskId={setPopoverTaskId}
                    setPopoverObject={setPopoverObject}
                    setPopoverObjectId={setPopoverObjectId}
                    setPopoverStage={setPopoverStage}
                    setPopoverGroup={setPopoverGroup}
                    setPopoverIdFirm={setPopoverIdFirm}
                    setPopoverNameFirm={setPopoverNameFirm}
                    setPopoverWorkType={setPopoverWorkType}
                    setPopoverDate={setPopoverDate}
                    setPopoverHour={setPopoverHour}
                    setPopoverDetails={setPopoverDetails}
                    setValue={setValue}
                    setAnchorEl={setAnchorEl}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    duplicateTasksHandler={(newTask) => {
                        console.log('are we here')
                        setNewTask(newTask)
                    }}
                    navigate={navigate}
                    popoverTaskId={popoverTaskId}
                    setWaitOpen={setWaitOpen}
                    setActiveOpen={setActiveOpen}
                    setCompleteOpen={setCompleteOpen}
                    deleteDialogOpen={deleteDialogOpen}
                    deleteTask={deleteTask}
                    setDeleteDialogOpen={setDeleteDialogOpen}
                    setPopoverAdditionalProperties={setPopoverAdditionalProperties}
                    valueChose={valueChose}
                    popoverStage={popoverStage}
                    popoverObject={popoverObject}
                    popoverObjectId={popoverObjectId}
                    popoverGroup={popoverGroup}
                    popoverWorkType={popoverWorkType}
                    popoverIdFirm={popoverIdFirm}
                    popoverNameFirm={popoverNameFirm}
                    popoverDate={popoverDate}
                    popoverHour={popoverHour}
                    popoverDetails={popoverDetails}
                    popoverAdditionalProperties={popoverAdditionalProperties}
                    moveOpen={moveOpen}
                    duplicateOpen={duplicateOpen}
                    setDuplicateOpen={setDuplicateOpen}
                    setMoveOpen={setMoveOpen}
                    setAddDetailDialogOpen={setAddDetailDialogOpen}
                    completeOpen={completeOpen}
                    activeOpen={activeOpen}
                    waitOpen={waitOpen}
                    newTask={newTask}
                />
                :
                <ListForObjectsComponent
                    navigate={navigate}
                    accessToken={accessToken}
                    setAccessToken={setAccessToken}/>
            }


        </React.Fragment>
    );*/

}

export default ListScreen;
